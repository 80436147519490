<template>
  <v-card max-width="426" class="mx-auto px-3 px-md-6 py-6">
    <h1 class="font-medium-20 gray13--text text-center mb-2">
      {{ $t("auth.loginPage.loginForm.loginToAccount") }}
    </h1>

    <p class="gray7--text text-center mb-6">
      {{ $t("auth.loginPage.loginForm.enterUserAndPass") }}
    </p>

    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        name="username"
        v-model="accountModel.username"
        :rules="emailRules"
        required
        :label="$t('auth.loginPage.loginForm.email')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-5"
      >
      </v-text-field>

      <v-text-field
        name="password"
        v-model="accountModel.password"
        :rules="passwordRules"
        required
        :type="showPassword ? 'text' : 'password'"
        :label="$t('auth.loginPage.loginForm.password')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-5"
      >
        <template v-slot:append>
          <div @click="showPassword = !showPassword" class="cursor-pointer">
            <v-icon size="24" v-if="showPassword">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <captcha
        class="mb-8"
        :reload="reloadCaptcha"
        @setCaptchaInfo="setCaptchaInfo"
      />

      <v-btn
        :disabled="!valid"
        color="primary"
        block
        depressed
        height="56"
        class="mb-4"
        :loading="loading"
        type="submit"
      >
        <span class="font-semiBold-14">
          {{ $t("auth.loginPage.loginForm.next") }}
        </span>
      </v-btn>

      <div class="text-center">
        <span class="gray7--text me-1">
          {{ $t("auth.loginPage.loginForm.forgotPassword") }}
        </span>

        <router-link
          to="/auth/login/password-recovery"
          class="font-semiBold-14 text-decoration-underline primary--text"
        >
          {{ $t("auth.loginPage.loginForm.reset") }}
        </router-link>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { AuthService } from "@/services";
import Captcha from "@/components/Auth/Captcha";

export default {
  name: "login-form",
  components: {
    Captcha,
  },
  props: [],
  data() {
    return {
      valid: false,
      loading: false,
      reloadCaptcha: false,
      showPassword: false,
      accountModel: {
        username: "",
        password: "",
        captchaAnswer: "",
        captchaKey: "",
      },
      emailRules: [
        (v) => !!v || this.$t("auth.loginPage.loginForm.emailNotEmpty"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t("auth.loginPage.loginForm.invalidEmail"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("auth.loginPage.loginForm.passwordNotEmpty"),
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    submit() {
      this.loading = true;
      this.reloadCaptcha = false;
      AuthService.login(this.accountModel)
        .then((res) => {
          localStorage.setItem("access_token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
          localStorage.setItem("isAuth", "true");
          this.$router.replace("/panel");
        })
        .finally(() => {
          this.accountModel.captchaAnswer = "";
          this.accountModel.captchaKey = "";
          this.loading = false;
          this.reloadCaptcha = true;
        });
    },
    setCaptchaInfo(data) {
      this.accountModel.captchaAnswer = data.captchaAnswer;
      this.accountModel.captchaKey = data.captchaKey;
    },
  },
};
</script>

<style scoped lang="scss">
@import "LoginForm";
</style>
